<template>
	<b-modal id="importwallet-modal" hide-footer  hide-header-close no-close-on-backdrop size="md" >

		<template #modal-header> 
      
			<div>
				<v-icon name="chevron-left" @click="close()" />
			</div>
			<h5>
				{{ $t('setWallet.importLabel') }}
			</h5> 
			<div></div>

    </template>


		<div  > 
				<div class="send-container" >
					<form v-on:submit.prevent="onImport"> 
	     			<div class="alert alert-danger" v-if="showError"> {{ systemMsg }} </div>
	     			
	   
					  
					   <div class="form-container">
					   	 <label for="privkey">{{ $t('setWallet.yourPrivateKey') }}:</label>
						  <input type="text" id="privateKey" name="privateKey" v-model="importWallet.privateKey"  class="form-control" >
						  <span class="error" v-if="errors.privateKey != ''"> {{ errors.privateKey }} </span> 

					  </div>
					  
					  
					  <div class="flex  margin-top">
	    				<button  class="btn btn-main" type="submit">  
	    					<span>{{ $t('setWallet.importButton') }}</span>	
	    				</button>
	    				 
	    			</div>
	    			
	    			
					</form>
				</div> 
		</div>
	</b-modal>
</template>

<script>

import qs from 'querystring'  
export default {
  name: 'ImportWallet',
  data: function() {
      return  {
      	showError: false,
      	importWallet: {
      		privateKey: ''
      	},
      	errors: { 
					privateKey: ""
				},
				systemMsg: ""
      }
    },
  	computed: {
    	init() {
        	return this.$store.state.init;
    	},
    	
   	},
   	methods: {
   		close: function(){
   			this.$bvModal.hide('importwallet-modal'); 
   		},
   		
			onImport: function(){
				if(localStorage.getItem("lang") == 'kr') {
					let hasError = false;

				
				
					if(this.importWallet.privateKey == '' || this.importWallet.privateKey == undefined || this.importWallet.privateKey == null) {
						this.errors.privateKey = '개인 키가 필요합니다.';
						hasError = true;
					}
					
					//console.log(hasError);
					if(!hasError) {
						this.showError = false;
						this.axios.post('/cx/address/import',
							qs.stringify({ 
								"privateKey": this.importWallet.privateKey
							}))
						.then((response) => { 
						
							console.log(response.data); 
										window.location.href = "/#/";
							
						}).catch((err) => {
				
				
							if(err) {
							this.showError = true;
													
														if(err.response.data.msgCode == "99999") {
															this.systemMsg = "개인 키가 잘못되었습니다."	
														} else {
															if(err.response.data.fieldErrors) {
																this.systemMsg = err.response.data.fieldErrors[0].defaultMessage;
															}
															
														}
														
													} 

						}).finally ( ()=> {
												setTimeout(() => {
													this.showError = false;
													this.systemMsg = '';
												},3000)
											
											})
					}
				} else {
					let hasError = false;

				
				
				if(this.importWallet.privateKey == '' || this.importWallet.privateKey == undefined || this.importWallet.privateKey == null) {
					this.errors.privateKey = 'Private key is required.';
					hasError = true;
				}
				
				//console.log(hasError);
				if(!hasError) {
					this.showError = false;
					 this.axios.post('/cx/address/import',
						qs.stringify({ 
						    "privateKey": this.importWallet.privateKey
						}))
		            .then((response) => { 
		             
		                console.log(response.data); 
									window.location.href = "/#/";
						
		            }).catch((err) => {
			
			
		                if(err) {
	                  	 this.showError = true;
												
													if(err.response.data.msgCode == "99999") {
														this.systemMsg = "Invalid Private Key."	
													} else {
														if(err.response.data.fieldErrors) {
															this.systemMsg = err.response.data.fieldErrors[0].defaultMessage;
														}
														
													}
													
												} 

		            }).finally ( ()=> {
											setTimeout(() => {
												this.showError = false;
												this.systemMsg = '';
											},3000)
										
										})
				}
				}
				
			},

   	},
}
</script>
 
<style scoped lang="scss">
	
  .send-container { 
	   
	    border-radius: 6px;
	    flex-direction: column;
	    position: relative;
	    background: #FFF; 
	    color: #000;
  }

   .error {
  	color: red;
  }

  button {
  	margin-top:1em;
  }

	
</style>
