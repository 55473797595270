<template>
  <div class="main-container"> 
    <img :src="require('../assets/bg1.jpg')" class="bg">

    <div class="wrapper">
      <div id="form">
        <div class="logo">
          <img :src="require('../assets/logo.png')" >
        </div>
        
        <h4 class="text-center" style="color:white;">{{ $t('setWallet.label') }}</h4>


        <div class="set-up-wallet" v-if="!isLoading" v-on:click="initImport">
            <img :src="require('../assets/existing.png')">
            <span>{{ $t('setWallet.import') }}</span>
        </div>

        <div class="set-up-wallet" v-on:click="generateWallet"  v-if="!successGenerate">
            <img v-if="!isLoading" :src="require('../assets/newwallet.png')">
            <span v-if="!isLoading">{{ $t('setWallet.create') }}</span>

            <div id="loading" v-if="isLoading">
              <div class="content"> 
                <span>{{ $t('setWallet.generateWallet') }}</span>
                <v-icon name="spinner" pulse scale="2" style="fill: #fff"/>

              </div>
            </div>
        </div>
        

        <div class="set-up-wallet" v-if="successGenerate">
          <p class="text-center">{{ $t('setWallet.generateSuccess1') }}</p>
          <p class="text-center">{{ $t('setWallet.generateSuccess2') }}</p>
          
        </div>

      </div>
    </div>
    
    <ImportWallet />
  </div>
</template>

<script>

  import qs from 'querystring'
  import ImportWallet from '@/components/modal/ImportWallet.vue'
  export default {
    name: 'SetupWallet',
    components: {
      ImportWallet
    },
    data: function() {
      return  {
       isLoading: false,
       successGenerate: false
      }
    },
    computed: {
     
    },
    methods: {
      initImport: function(){
        this.$bvModal.show('importwallet-modal'); 
      },

      checkSession: function() {

        const options = {
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          url: '/cx/sessioncheck',
        };

        this.axios(options)
          .then((response) => {
 
            }).catch((err) => {
                
              console.log(err.response.data)     

              if(err.response.data.msgCode == '00001') {
                window.location.href = "/#/login";
              }  
            })
      },

      generateWallet: function(){
        this.isLoading = true;
        const options = {
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          url: '/cx/cryptoaddress/CEZA',
        };

        this.axios(options)
          .then((response) => {
              if(response.data.addressValue != undefined && response.data.addressValue != null && response.data.addressValue != '') {
                //

                this.successGenerate = true;
                setTimeout(() => {
                  window.location.href = "/"; 
                },5000)
              } else {
                this.generateWallet();
              }

            }).catch((err) => {
                
              console.log(err.response.data)     

              
            })
       
      }

    },

    beforeMount(){

      // this.checkSession();
      
    }
  }
</script>

<style scoped lang="scss">

  #loading {
    .content {
      display: flex;
    align-items: center;
    justify-content: center;
        flex-direction: column;
    }
  }
  .set-up-wallet {
    background: #0567d7;
    padding: 1em;
    margin-top: 1em;
    border-radius: 6px;
    cursor: pointer;

    img {
      width: 25px;
      margin-right: 10px;
    }
  }

  .main-container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 1em;
  }
  .logo {
    display:flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1em;
    img{
      width: 100px;  
    }
    
  }

 .wrapper { 
  background: linear-gradient(to right, #020141, #0f0611);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 2em;
     color: #FFF;
  }


  /*med*/
  @media(min-width: 40em) {
    
  }
  /*large*/
  @media(min-width: 64em) {

   
  }
  /*xl*/
  @media(min-width: 87.5em) {
    
  }


  /*sm*/
  @media(max-width: 39.9375em) {
    .wrapper {
      padding: 1em !important;
      h4 {
        font-size: 1.25em;
      }

      .email-or-mobile { 

        .btn {
          font-size: 1em;
        }
      }
    }

    button, input {
      font-size: 85%;
    }
  }
  /*med*/
  @media(max-width: 63.9375em) {

     #app {
      
      padding: 2em;
      font-size: 85%;

    }
  }

  /*large*/
  @media(max-width: 87.4375em) {
    
  }

  @media(max-width: 768px) {
 
  }
</style>



